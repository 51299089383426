<template>
    <div class="row">
        <div class="col-md-12">
            <label class="form-label">Enflasyon Oranı</label>
            <input type="text" class="form-control" v-model="value.Inflation" placeholder="Enflasyon Oranı" />
        </div>
    </div>
</template>
<script>
export default{
    props:{
        value:Object
    }
}
</script>