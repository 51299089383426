<template>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-md-12 mb-1">
                    <label class="form-label">Başlangıç Bölgesi</label>
                    <v-select-list v-model="startRegion" placeholder="Başlangıç Bölgesi" label="RegionName" :options="startRegions"/>   
                </div>
                <div class="col-md-12 mb-1">
                    <label class="form-label">Bitiş Bölgesi</label>
                    <v-select-list v-model="endRegion" placeholder="Bitiş Bölgesi" label="RegionName" :options="endRegions"/>   
                </div>
                <div class="col-md-12 mb-1">
                    <label class="form-label">Taşıma Tipi</label>
                    <v-select-list placeholder="Taşıma Tipi" v-model="transportStatus" label="Name" :options="vehicleTransportStatuses"/>
                </div>
                <div class="col-md-12 mb-1">
                    <label class="form-label">Stok</label>
                    <v-select-list v-model="stok" :options="stoks" label="Name" placeholder="Stok" :loading="stokLoading"></v-select-list>
                </div>
                <div class="col-md-12 mb-1">
                    <label class="form-label">Araç Tipi</label>
                    <v-select-list :options="vehicleTypes" v-model="vehicleType" placeholder="Araç Tipi" label="Name"></v-select-list>
                </div>
            </div>
        </div>
        <div class="col-12">
            <table class="table table-sm table-bordered mb-0">
                <thead>
                    <tr>
                        <th class="text-center px-50">Sil</th>
                        <th class="w-50">Tarih</th>
                        <th class="w-50">Fiyat</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(r,i) in routes">
                        <tr>
                            <td class="p-25">
                                <button v-on:click="$delete(value.RoutePrices,i)":disabled="(value.RoutePrices || []).length <= 1" class="btn btn-icon btn-sm btn-flat-danger"><FeatherIcon icon="TrashIcon"/></button>
                            </td>
                            <td>
                                <v-date-picker placeholder="Tarih" v-model="r.StartDate"></v-date-picker>
                            </td>
                            <td>
                                <v-currency-input input-class="text-right" :value="r.Price" v-on:update-value="currencyAmount => r.Price = currencyAmount"></v-currency-input>
                            </td>
                        </tr>
                    </template>
                    <tr>
                        <td colspan="100" class="p-25">
                            <button v-on:click="addNew" class="btn btn-secondary btn-sm w-100">Ekle</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { SystemDataApi } from '@/api/system-data'
import {VehicleTypeApi} from '@/api/vehicle-type'
import {TransferRegionApi} from '@/api/transfer-region'
import {MaterialApi} from '@/api/material'
import VCurrencyInput from '@/components/VCurrencyInput.vue'
import moment from 'moment'

export default {
    components:{
        VCurrencyInput
    },  
    props:{
        value:Object
    },
    data(){
        return {
            startRegions:[],
            endRegions:[],
            stoks:[],
            vehicleTransportStatuses:[],
            vehicleTypes:[],
            stokLoading:null
        }
    },
    mounted(){
        this.getRegions()
        this.getVehicleTransportStatuses()
        this.getVehicleTypes()
        this.getStoks()
    },
    methods:{
        getVehicleTypes(){
            VehicleTypeApi.List().then(res => {
                this.vehicleTypes = res.data.Data
            }).catch(err=> {
                console.error(err)
            })
        },
        getRegions(){
            TransferRegionApi.List().then(res=>{
                this.startRegions = res.data.Data
                this.endRegions = res.data.Data
            })
        },
        getStoks(){
            this.stokLoading = true
            MaterialApi.List().then(res => {
                this.stoks = res.data.Data
                this.stokLoading = false
            }).catch(err=> {
                console.log(err)
                this.stokLoading = false
            })
        },
        getVehicleTransportStatuses(){
            SystemDataApi.VehicleTransportStatuses().then(res=> {
                this.vehicleTransportStatuses = res.data.Data
            })
        },
        addNew(){
            this.value.RoutePrices.push({StartDate:null,Price:null})
        }
    },
    computed:{
        stok:{
            get:function(){
                return this.stoks.find(s=>s.Code==this.value.StockCode)
            },
            set:function(value){
                this.value.StockCode = value?.Code || null
                this.value.StockName = value?.Name || null
            }
        },
        vehicleType:{
            get:function(){
                return this.vehicleTypes.find(s=>s.Id==this.value.VehicleTypeId)
            },
            set:function(value){
                this.value.VehicleTypeName = value?.Name || null
                this.value.VehicleTypeId = value?.Id || null
            }
        },
        transportStatus:{
            get:function(){
                return this.vehicleTransportStatuses.find(b=>b.Value==this.value.Status)
            },
            set:function(value){
                this.value.Status = value == null ? null : value.Value 
            }
        },
        startRegion:{
            get:function(){
                return this.startRegions.find(b=>b.Id==this.value.StartRegionId)
            },
            set:function(value){
                this.value.StartRegionId = value?.Id || null
            }
        },
        endRegion:{
            get:function(){
                return this.endRegions.find(b=>b.Id==this.value.EndRegionId)
            },
            set:function(value){
                this.value.EndRegionId = value?.Id || null
            }
        },
        routes(){
            return this.value.RoutePrices.sort((a,b) => (moment(a.StartDate,'YYYY-MM-DD') - moment(b.StartDate,'YYYY-MM-DD')))
        }
    }
}
</script>