<template>
    <div>
        <b-card>
            <b-modal id="update-inflation" title="Enflasyon Oranı Güncelle" no-close-on-esc no-close-on-backdrop>
                <update-inflation v-model="InflationData"></update-inflation>
                <template #modal-footer><v-executer :action="updateInflation"></v-executer></template>
            </b-modal>
            <b-modal id="update-price" title="Rota Fiyat Güncelle" no-close-on-esc no-close-on-backdrop>
                <update-route-price v-model="currentRoutePrice"></update-route-price>
                <template #modal-footer><v-executer :loading="priceUpdating" :action="updatePrice" text="Güncelle" :disabled="$safe(currentRoutePrice,'Price') == $safe(currentRoutePrice,'NewPrice')"></v-executer></template>
            </b-modal>
            <b-modal id="add-route" title="Yeni Rota" no-close-on-esc no-close-on-backdrop>
                <add-route v-model="route"></add-route>
                <template #modal-footer><v-executer :disabled="hasEmpty" :action="addRoute" :loading="routeAdding"></v-executer></template>
            </b-modal>
            <div class="table-responsive mb-0">
                <table class="table table-bordered table-sm align-middle">
                    <thead>
                        <tr>
                            <th class="w-20 text-nowrap">Kod</th>
                            <th class="w-20 text-nowrap">Firma Adı</th>
                            <th class="w-20 text-nowrap">Dış Anlaşma Kodu</th>
                            <th class="w-20 text-nowrap">Dış Anlaşma Adı</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-nowrap">{{ Agreement.CariCode }}</td>
                            <td class="text-nowrap">{{ Agreement.CariName }}</td>
                            <td class="text-nowrap">{{Agreement.AgreementCode}}</td>
                            <td class="text-nowrap">{{ Agreement.Name }}</td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <!-- <div class="row table-responsive">
                <table class="table table-bordered w-100 table-sm align-middle mt-2">
                    <thead>
                        <tr>
                            <th class="w-25 text-nowrap">Fiyata Enflasyon Etki Oranı</th>
                            <th class="w-25 text-nowrap">Enflasyon Oranı</th>
                            <th class="w-25 text-nowrap">Fiyata Yakıt Etki Oranı</th>
                            <th class="w-25 text-nowrap">Yakıt Fiyatı Artış/Azalış</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-nowrap">%{{ Agreement.AgreementPriceFactor.InflationAffectValue }}</td>
                            <td class="text-nowrap">%{{Agreement.AgreementPriceFactor.InflationAffectRateValue}}</td>
                            <td class="text-nowrap">%{{ Agreement.AgreementPriceFactor.FuelAffectValue}}</td>
                            <td class="text-nowrap">%{{Agreement.AgreementPriceFactor.FuelAffectRateValue}}</td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </b-card>
        <b-card>
            <div class="row justify-content-between align-items-center">
                <div class="col mx-50">
                    <h2 class="mb-0">Rota Fiyatları</h2>
                </div>
                <div class="col-auto">
                    <div class="row">
                        <div class="col-auto">
                            <v-executer variant="success" :action="downloadRoutes" :loading="routesDownloading">
                                <div class="d-flex align-items-center">
                                    <feather-icon size="14" class="mr-50" icon="FileIcon"/>
                                    Excel
                                </div>
                            </v-executer> 
                        </div>
                        <div class="col-auto ">
                            <button class="btn btn-secondary" v-on:click="showAddRoute">Yeni Rota</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-1 table-responsive mb-0" >
                    <table class="table table-bordered table-sm align-middle mb-0">
                        <thead>
                            <tr>
                                <th class="text-nowrap">Sıra</th>
                                <th class="text-nowrap">Başlangıç Bölgesi</th>
                                <th class="text-nowrap">Bitiş Bölgesi</th>
                                <th class="text-nowrap">Araç Tipi</th>
                                <th class="text-nowrap">Ürün Tipi</th>
                                <th class="text-nowrap">Taşıma Tipi</th>
                                <th class="text-nowrap">Fiyat</th>
                                <th class="text-nowrap">Son Güncelleme Tarihi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(data,i) in Agreement.AgreementRoutePrices">
                                <tr :key="data.Id">
                                    <td class="text-nowrap text-center font-weight-bolder p-0">{{ i+1 }}</td>
                                    <td class="text-nowrap">{{ data.StartRegionName }}</td>
                                    <td class="text-nowrap">{{ data.EndRegionName }}</td>
                                    <td class="text-nowrap">{{ data.VehicleTypeName }}</td>
                                    <td class="text-nowrap">{{ data.StockName }}</td>
                                    <td class="text-nowrap">{{ data.StatusStr }}</td>
                                    <td class="text-nowrap text-right">
                                        <div class="d-flex align-items-center justify-content-end">
                                            <b-badge class="primary">{{ data.PriceStr +'₺'}} </b-badge>
                                            <button v-on:click="showUpdatePrice(data)" class="btn btn-xxs btn-icon btn-flat-warning ml-25"><feather-icon icon="EditIcon"/></button>
                                        </div>
                                    </td>
                                    <td class="text-nowrap">{{ data.StartDate }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { BBadge, BCard,BFormCheckbox } from 'bootstrap-vue'
import { AgreementApi } from '@/api/agreement'
import UpdateInflation from './components/UpdateInflation.vue'
import VStatsBox from '@/components/VStatsBox.vue'
import Additional from '@/utilities/additional'
import VFileUploader from '@/components/v-file-uploader/VFileUploader.vue'
import { serialize } from 'object-to-formdata'
import Auth from '@/utilities/auth'
import AddRoute from './components/AddRoute.vue'
import UpdateRoutePrice from './components/UpdateRoutePrice.vue'

export default {
    components:{
        VFileUploader,
        BCard,
        BBadge,
        VStatsBox,
        BFormCheckbox,
        UpdateInflation,
        AddRoute,
        UpdateRoutePrice
    },
    data(){
        return{
            InflationData:{
                Inflation:null
            },
            fileUploading:false,
            File:{
                AgreementDocument:null
            },
            Agreement:{
                Id:null,
                FirmCariCode:null,
                AgreementCode:null,
                CariName:null,
                CariCode:null,
                CreateTime:null,
                AgreementDocumentUrl:null,
                Name:null,
                FirmId:null,
                CurrentFuelPrice:null,
                ExpireDate:null,
                AgreementRoutePrices:[],
                AgreementPriceFactor:{
                    FuelAffectRateValue:null,
                    FuelAffectRateValueActive:null,
                    FuelAffectValue:null,
                    FuelAffectValueActive:null,
                    InflationAffectValue:null,
                    InflationAffectValueActive:null,
                    InflationAffectRateValueActive:null,
                    InflationAffectRateValue:null
                },
            },
            routeAdding:false,
            route:{
                AgreementId:null,
                Status:null,
                StartRegionId:null,
                EndRegionId:null,
                StockCode:null,
                StockName:null,
                VehicleTypeId:null,
                RoutePrices:[
                    {Price:null,StartDate:null}
                ]
            },
            currentRoutePrice:null,
            priceUpdating:false,
            routesDownloading:false
        }
    },
    mounted(){
        this.getDetail()
    },
    methods:{
        isInRole(roles){
            let x = Auth.hasRole([roles])
            return x
        },
        fileUpload(){
            this.fileUploading = true
            let data ={
                AgreementDocument: this.File.AgreementDocument,
                AgreementId:this.Agreement.Id
            }
            const x = serialize(data,{indices: true,dotsForObjectNotation: true,nullsAsUndefineds:true})
            AgreementApi.UploadAgreementDocument(x).then(res=>{
                this.$result(res)
                this.fileUploading = false
                if(res.data.Success){
                    this.getDetail()
                }
            }).catch(err=>{
                this.$result(null,err)
                this.fileUploading = false
            })
        },
        downloadAgreementDocument(){
            Additional.GetBlobFile(this.Agreement.AgreementDocumentUrl).then(c=>{
                const name = 'Sözleşme.pdf';  
                Additional.DownloadFile(c.data,name)
            })
        },
        downloadRoutes(){
            const id = this.$route.params.id
            this.routesDownloading = true
            AgreementApi.DownloadRoutes(id).then(res => {
                Additional.DownloadFile(res.data, [this.Agreement.CariCode,this.Agreement.AgreementCode,this.Agreement.Name,'Rota Fiyatlar'].join(' - ').concat('.xlsx'))
                this.routesDownloading = false
            })
        },
        getDetail(){
            const id = this.$route.params.id
            AgreementApi.Detail(id).then(res => {
                this.Agreement.Id = res.data.Data.Id
                this.Agreement.CreateTime = res.data.Data.CreateTime
                this.Agreement.FirmCariCode = res.data.Data.FirmCariCode
                this.Agreement.AgreementCode = res.data.Data.AgreementCode
                this.Agreement.CariName = res.data.Data.CariName
                this.Agreement.CariCode = res.data.Data.CariCode
                this.Agreement.Name = res.data.Data.Name
                this.Agreement.AgreementRoutePrices = res.data.Data.AgreementRoutePrices
                this.Agreement.AgreementDocumentUrl = res.data.Data.AgreementDocumentUrl
                this.Agreement.CurrentFuelPrice = res.data.Data.CurrentFuelPrice
                this.Agreement.ExpireDate = res.data.Data.ExpireDate
                this.Agreement.StartDate = res.data.Data.StartDate
                this.Agreement.AgreementPriceFactor.FuelAffectRateValue = res.data.Data.AgreementPriceFactor.FuelAffectRateValue
                this.Agreement.AgreementPriceFactor.FuelAffectRateValueActive = res.data.Data.AgreementPriceFactor.FuelAffectRateValueActive
                this.Agreement.AgreementPriceFactor.FuelAffectValueActive = res.data.Data.AgreementPriceFactor.FuelAffectValueActive
                this.Agreement.AgreementPriceFactor.FuelAffectValue = res.data.Data.AgreementPriceFactor.FuelAffectValue
                this.Agreement.AgreementPriceFactor.InflationAffectValue = res.data.Data.AgreementPriceFactor.InflationAffectValue
                this.Agreement.AgreementPriceFactor.InflationAffectValueActive = res.data.Data.AgreementPriceFactor.InflationAffectValueActive
                this.Agreement.AgreementPriceFactor.InflationAffectRateValueActive = res.data.Data.AgreementPriceFactor.InflationAffectRateValueActive
                this.Agreement.AgreementPriceFactor.InflationAffectRateValue = res.data.Data.AgreementPriceFactor.InflationAffectRateValue
            }).catch(err => {
                console.error(err);
            })
        },
        inflationUpdateModal(){
            this.$showModal('update-inflation')
        },
        updateInflation(){
            AgreementApi.UpdateInflationRate({
                InflationRate: this.InflationData.Inflation,
                AgreementId:this.$route.params.id
            }).then(res => {
                this.$hideModal('update-inflation')
                this.getDetail()
                this.InflationData.Inflation = 0
                this.$result(res)
            })
        },
        provider(data){
            return AgreementApi.Table(data)
        },
        createNew(){
            this.$router.push({name:'agreement-create'})
        },
        delete(item){
            this.$confirm('Silmek istediğinize emin misiniz?',`Bu işlem ${item.FirmName} firmasıyla olan ${item.Name} adlı dış anlaşmayı silecektir ve geri alınamaz.`).then(result=>{
                if(result){
                    AgreementApi.Delete(item.Id).then(res=>{
                        if(res.data.Success) {
                            this.$refs.table.refresh()
                        }
                        this.$result(res)
                        
                    }).catch(err=>{
                        this.$result(null,err)
                    })
                }
            })
        },
        showAddRoute(){
            this.route.AgreementId = this.$route.params.id
            this.route.Status = null
            this.route.StartRegionId = null
            this.route.EndRegionId = null
            this.route.StockCode = null
            this.route.StockName = null
            this.route.VehicleTypeId = null
            this.route.RoutePrices = [{Price:null,StartDate:null}]
            this.$showModal('add-route')
        },
        addRoute(){
            this.routeAdding = true
            AgreementApi.AddRoute(this.route).then(res=>{
                this.routeAdding = false
                if(res.data.Success) {
                    this.getDetail()
                    this.$hideModal('add-route')
                }
                this.$result(res)
                
            }).catch(err=>{
                this.routeAdding = false
                this.$result(null,err)
            })
        },
        showUpdatePrice(rp){
            this.currentRoutePrice = null
            this.currentRoutePrice = {
                ...rp,
                NewPrice:rp.Price
            }
            this.$showModal('update-price')
        },
        updatePrice(){
            this.priceUpdating=true
            AgreementApi.UpdateRoutePrice({
                Id: this.currentRoutePrice.Id,
                Price: this.currentRoutePrice.NewPrice
            }).then(res => {
                if(res.data.Success){
                    this.$hideModal('update-price')
                    this.getDetail()
                }
                this.$result(res)
                this.priceUpdating=false
            }).catch(err => {
                this.$result(null,err)
                this.priceUpdating=false
            })
        }
    },
    computed:{
        hasEmpty(){
            return this.route.RoutePrices.some(r => r.Price == null || r.StartDate == null)
        }
    }
}
</script>