<template>
    <div class="row">
        <div class="col-12 mb-1">
            <table class="table table-bordered table-sm mb-0">
                <tbody>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Başlangıç Bölgesi</td>
                        <td class="w-50">{{ $safe(value,'StartRegionName') }}</td>
                    </tr>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Bitiş Bölgesi</td>
                        <td class="w-50">{{ $safe(value,'EndRegionName') }}</td>
                    </tr>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Araç Tipi</td>
                        <td class="w-50">{{ $safe(value,'VehicleTypeName') }}</td>
                    </tr>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Ürün Tipi</td>
                        <td class="w-50">{{ $safe(value,'StockName') }}</td>
                    </tr>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Taşıma Tipi</td>
                        <td class="w-50">{{ $safe(value,'StatusStr') }}</td>
                    </tr>
                    <tr>
                        <td class="w-50 font-weight-bolder bg-light">Son Güncelleme Tarihi</td>
                        <td class="w-50">{{ $safe(value,'StartDate') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 mb-50">
            <h5 class="mb-0 text-muted text-right">Güncel Fiyat: <b class="text-secondary">{{ $safe(value,'PriceStr') }}₺</b></h5>
        </div>
        <div class="col-12">
            <label class="form-label">Yeni Fiyat</label>
            <v-currency-input input-class="text-right" :value="value.NewPrice" v-on:update-value="currencyAmount => value.NewPrice = currencyAmount"></v-currency-input>
        </div>
    </div>
</template>
<script>
import VCurrencyInput from '@/components/VCurrencyInput.vue'
export default{
    components:{
        VCurrencyInput
    },
    props:{
        value:Object
    },
}
</script>